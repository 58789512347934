export const CHECK_PINCODE_REQUEST = "CHECK_PINCODE_REQUEST";
export const CHECK_PINCODE_SUCCESS = "CHECK_PINCODE_SUCCESS";
export const CHECK_PINCODE_FAIL = "CHECK_PINCODE_FAIL";
export const CHECK_PINCODE_RESET = "CHECK_PINCODE_RESET";

export const TRACK_SHIPMENT_REQUEST = "TRACK_SHIPMENT_REQUEST";
export const TRACK_SHIPMENT_SUCCESS = "TRACK_SHIPMENT_SUCCESS";
export const TRACK_SHIPMENT_FAIL = "TRACK_SHIPMENT_FAIL";
export const TRACK_SHIPMENT_RESET = "TRACK_SHIPMENT_RESET";


export const CLEAR_ERRORS = "CLEAR_ERRORS";
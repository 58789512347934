export const ADMIN_COUPON_REQUEST = "ADMIN_COUPON_REQUEST";
export const ADMIN_COUPON_SUCCESS = "ADMIN_COUPON_SUCCESS";
export const ADMIN_COUPON_FAIL = "ADMIN_COUPON_FAIL";

export const COUPON_DETAILS_REQUEST = "COUPON_DETAILS_REQUEST";
export const COUPON_DETAILS_SUCCESS = "COUPON_DETAILS_SUCCESS";
export const COUPON_DETAILS_FAIL = "COUPON_DETAILS_FAIL";

export const NEW_COUPON_REQUEST = "NEW_COUPON_REQUEST";
export const NEW_COUPON_SUCCESS = "NEW_COUPON_SUCCESS";
export const NEW_COUPON_RESET = "NEW_COUPON_RESET";
export const NEW_COUPON_FAIL = "NEW_COUPON_FAIL";

export const UPDATE_COUPON_REQUEST = "UPDATE_COUPON_REQUEST";
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS";
export const UPDATE_COUPON_RESET = "UPDATE_COUPON_RESET";
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL";

export const DELETE_COUPON_REQUEST = "DELETE_COUPON_REQUEST";
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS";
export const DELETE_COUPON_RESET = "DELETE_COUPON_RESET";
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL";

export const APPLY_COUPON_REQUEST = "APPLY_COUPON_REQUEST";
export const APPLY_COUPON_SUCCESS = "APPLY_COUPON_SUCCESS";
export const APPLY_COUPON_FAIL = "APPLY_COUPON_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";